import { Link, graphql } from "gatsby";
import React, { FC } from "react";
import { Kenro2PaneLayout } from "~components/templates/kenro-2pane-layout/page";
import { SEO } from "~components/templates/seo";
import { Button } from "~components/utils/button";
import * as styles from "./index.module.scss";

type Props = {
  data: GatsbyKenroThankyouPageQuery;
};

const Component: FC<Props> = () => {
  return (
    <>
      <SEO meta={[{ name: "robots", content: "noindex" }]} />
      <Kenro2PaneLayout>
        <div className={styles.Thankyou}>
          <div className={styles.Thankyou_Container}>
            <h1 className={styles.Thankyou_Heading}>
              お問い合わせ
              <br />
              ありがとうございます
            </h1>
            <p className={styles.Thankyou_Message}>
              入力いただいたメールアドレスにサービス資料をお送りしておりますので、ご確認ください。
              <br />
              導入やトライアルをご検討の皆様には後ほど弊社よりご連絡を差し上げます。
            </p>
            <Link to="/kenro">
              <Button buttonTheme="Kenro">KENRO トップページへ</Button>
            </Link>
          </div>
        </div>
      </Kenro2PaneLayout>
    </>
  );
};

export const query = graphql`
  query KenroThankyouPage {
    logo_color: file(relativePath: { eq: "kenro/logo_color.svg" }) {
      publicURL
    }
  }
`;

export default Component;
